const dialog = document.querySelector("dialog");
const dialogImg = document.querySelector("dialog img");

for (const button of document.querySelectorAll("#calendar > button")) {
  const day = parseInt(button.innerText);
  button.addEventListener("mouseenter", () => {
    const now = new Date();
    const cheat = document.location.search.includes("cheat");
    if (!cheat && (now.getMonth() !== 11 || day > now.getDate())) {
      button.setAttribute("aria-pressed", "mixed");
    } else {
      button.style.backgroundImage = `url('images/${day}.avif')`;
    }
  });
  button.addEventListener("click", () => {
    if (button.getAttribute("aria-pressed") === "mixed") return;
    if (button.hasAttribute("aria-pressed")) {
      dialogImg.setAttribute("src", `images/${day}.avif`);
      dialog.showModal();
    } else {
      button.setAttribute("aria-pressed", true);
    }
  });
}

dialog.addEventListener("click", () => {
  dialog.close();
});
